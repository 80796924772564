<template>
  <b-row class="mb-4">
    <b-col
      cols="12"
      md="4"
      lg="3"
      class="mb-3"
    >
      <h5 class="font-weight-bolder">
        {{ this.$t('pages.airportTransferService.wizard.tabs.transfersDetails.equipmentAndExtras') }}
      </h5>
    </b-col>

    <b-col
      cols="12"
      md="8"
      lg="9"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('pages.airportTransferService.wizard.boosterSeat')"
            label-for="booster-seats"
          >
            <b-form-spinbutton
              id="booster-seats"
              v-model="extras.boosterSeats"
              min="0"
              max="10"
              class="mb-2"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('pages.airportTransferService.wizard.babyTrolleyFree')"
            label-for="baby-trolleys"
          >
            <b-form-spinbutton
              id="baby-trolleys"
              v-model="extras.babyTrolleys"
              min="0"
              max="10"
              class="mb-2"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('pages.airportTransferService.wizard.maxiCosi')"
            label-for="maxi-cosis"
          >
            <b-form-spinbutton
              id="maxi-cosis"
              v-model="extras.maxiCosis"
              min="0"
              max="10"
              class="mb-2"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('pages.airportTransferService.wizard.babySeat')"
            label-for="baby-seats"
          >
            <b-form-spinbutton
              id="baby-seats"
              v-model="extras.babySeats"
              min="0"
              max="10"
              class="mb-2"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('pages.airportTransferService.wizard.bicycle')"
            label-for="bicycles"
          >
            <b-form-spinbutton
              id="bicycles"
              v-model="extras.bicycles"
              min="0"
              max="10"
              class="mb-2"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            :label="$t('pages.airportTransferService.wizard.golfSet')"
            label-for="golfSet"
          >
            <b-form-spinbutton
              id="golfSet"
              v-model="extras.golfSets"
              min="0"
              max="10"
              class="mb-2"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormSpinbutton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormSpinbutton,
  },
  data() {
    return {
      extras: {
        boosterSeats: 0,
        babyTrolleys: 0,
        maxiCosis: 0,
        babySeats: 0,
        bicycles: 0,
        golfSets: 0,
      },
    }
  },
  computed: {
    extrasCount() {
      return Object.values(this.extras).reduce((acc, value) => acc + value, 0)
    },
  },
  watch: {
    extras: {
      handler(extras) {
        this.$emit('updated', this.extrasCount > 0 ? extras : null)
      },
      deep: true,
    },
  },
}
</script>
