<template>
  <b-card id="booking-selector">
    <b-row class="mb-4">
      <b-col>
        <h4
          class="text-uppercase fr-font-weight-super-bold fr-title-mobile-size fr-title-desktop-size"
        >
          {{ $t("pages.airportTransferService.bookingSelector.title") }}
        </h4>
      </b-col>
    </b-row>

    <b-row v-if="loadingBookings">
      <b-col
        v-for="index in 2"
        :key="`booking-placeholder-${index}`"
        cols="12"
        md="6"
      >
        <booking-selector-item-placeholder />
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col
        v-for="booking in currentBookings"
        :key="`booking-item-${booking.uuid}`"
        cols="12"
        md="6"
      >
        <booking-selector-item
          :booking="booking"
          @selected="onBookingSelected(booking)"
          @contact="onContactForBooking(booking)"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import BookingSelectorItem from "@/views/foravila/services/airport-transfer/components/BookingSelectorItem.vue";
import BookingSelectorItemPlaceholder from "@/views/foravila/services/airport-transfer/components/BookingSelectorItemPlaceholder.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BookingSelectorItem,
    BookingSelectorItemPlaceholder
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem("foravilaGuestsUserData"));
    },
    clientUuid() {
      return this.userData.client.uuid;
    },
    loadingClient() {
      return this.$store.getters["client/loading"];
    },
    client() {
      return this.$store.getters["client/client"];
    },
    loadingBookings() {
      return this.$store.getters["bookings/loading"];
    },
    currentBookings() {
      return this.$store.getters["bookings/currentBookings"];
    }
  },
  created() {
    if (!this.client && !this.loadingClient) this.fetchClient();
    if (!this.currentBookings.length && !this.loadingBookings)
      this.fetchBookings();
  },
  methods: {
    fetchClient() {
      this.$store
        .dispatch("client/fetchClient", this.clientUuid)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.text")
          )
        );
    },
    fetchBookings() {
      this.$store
        .dispatch("bookings/fetchBookings", { pagination: false })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.text")
          )
        );
    },
    onBookingSelected(booking) {
      this.$emit("selected", booking);
    },
    onContactForBooking(booking) {
      this.$emit("contact", booking);
    }
  }
};
</script>

<style lang="scss">
#booking-selector {
  #booking-selector-bookings-list {
    min-height: 300px;
  }
}
</style>
