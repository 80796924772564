<template>
  <div class="airport-transfer-hero-card">
    <b-row class="pb-3">
      <b-col cols="12">
        <div
          class="airport-transfer-hero-card-title d-flex flex-column justify-content-center"
        >
          <h1
            class="text-center text-white text-uppercase fr-font-weight-super-bold fr-title-mobile-size fr-title-desktop-size"
          >
            {{ $t("pages.airportTransferService.heroTitle") }}
          </h1>
          <h4
            class="text-center text-white fr-subtitle-mobile-size fr-subtitle-desktop-size"
          >
            {{ $t("pages.airportTransferService.heroSubtitle") }}
          </h4>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol
  }
};
</script>

<style lang="scss">
.airport-transfer-hero-card {
  .airport-transfer-hero-card-title {
    height: 300px;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.705),
        rgba(116, 116, 116, 0.473)
      ),
      url("~@/assets/foravila/images/services/transfer-service-hero.jpeg");
    background-position: center center;
    background-size: cover;
  }

  .hero-item {
    height: 100%;
  }
}
</style>
