<template>
  <b-card class="airport-transfer-how-it-works-card">
    <b-row>
      <b-col>
        <div>
          <h1
            class="text-uppercase text-center fr-font-weight-super-bold fr-title-mobile-size fr-title-desktop-size"
          >
            {{ $t("pages.airportTransferService.howDoesItWorks.Title") }}
          </h1>
          <h4
            class="fr-subtitle-mobile-size fr-subtitle-desktop-size text-center"
          >
            {{ $t("pages.airportTransferService.howDoesItWorks.Subtitle") }}
          </h4>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-between bg-white">
      <b-col
        v-for="(step, stepIndex) in steps"
        :key="`step-${stepIndex}`"
        cols="12"
        :md="Math.floor(12 / steps.length)"
        class="d-flex flex-column align-items-center"
      >
        <b-img class="img" :src="step.img" />
        <h4>
          {{ step.title }}
        </h4>
        <p class="text-center">
          {{ step.subtitle }}
        </p>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BImg, BRow, BCol } from "bootstrap-vue";

export default {
  components: { BCard, BImg, BRow, BCol },

  computed: {
    steps() {
      return [
        {
          // eslint-disable-next-line global-require
          img: require("@/assets/foravila/images/illustrations/book-transfer-service.svg"),
          title: this.$t("pages.airportTransferService.howDoesItWorks.1Title"),
          subtitle: this.$t("pages.airportTransferService.howDoesItWorks.1Text")
        },
        {
          // eslint-disable-next-line global-require
          img: require("@/assets/foravila/images/illustrations/meet-transfer-service.svg"),
          title: this.$t("pages.airportTransferService.howDoesItWorks.2Title"),
          subtitle: this.$t("pages.airportTransferService.howDoesItWorks.2Text")
        },
        {
          // eslint-disable-next-line global-require
          img: require("@/assets/foravila/images/illustrations/enjoy-transfer-service.svg"),
          title: this.$t("pages.airportTransferService.howDoesItWorks.3Title"),
          subtitle: this.$t("pages.airportTransferService.howDoesItWorks.3Text")
        }
      ];
    }
  }
};
</script>

<style lang="scss">
.airport-transfer-how-it-works-card {
  img {
    width: 180px;
  }
}
</style>
