var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:("clientFormRules" + _vm.formId)},[_c('b-form',{attrs:{"id":("airport-transfer-form-" + _vm.formId)}},[_c('b-row',[_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","md":"4"}},[_c('b-row',[(_vm.pickupLocationText)?_c('b-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(this.$t("pages.airportTransferService.wizard.pickupLocation"))+" ")]),_c('h5',{staticClass:"font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.pickupLocationText)+" ")])]):_vm._e(),(_vm.dropoffLocationText)?_c('b-col',{attrs:{"cols":"12","sm":"6","md":"12"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(this.$t("pages.airportTransferService.wizard.dropoffLocation"))+" ")]),_c('h5',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.dropoffLocationText)+" ")])]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('b-row',[_c('b-col',{staticClass:"mb-3 mb-sm-0",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pages.airportTransferService.wizard.pickupDate'),"label-for":("pickup-date-" + _vm.formId)}},[_c('validation-provider',{attrs:{"name":_vm.$t('pages.airportTransferService.wizard.pickupDate'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"w-full mb-2",attrs:{"id":("pickup-date-" + _vm.formId),"locale":_vm.$i18n.locale,"start-weekday":1,"show-decade-nav":true,"date-format-options":{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  },"hide-header":"","label-no-date-selected":"","tabindex":"1","state":errors.length > 0 ? false : null,"min":_vm.tomorrow},model:{value:(_vm.updatedTransfer.pickupDate),callback:function ($$v) {_vm.$set(_vm.updatedTransfer, "pickupDate", $$v)},expression:"updatedTransfer.pickupDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-3 mb-sm-0",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pages.airportTransferService.wizard.pickupTime'),"label-for":("pickup-time-" + _vm.formId)}},[_c('validation-provider',{attrs:{"name":_vm.$t('pages.airportTransferService.wizard.pickupTime'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mb-0",attrs:{"id":("pickup-time-" + _vm.formId),"label-no-time-selected":"","locale":_vm.timepickerLocale,"minutes-step":"10","hide-header":"","no-close-button":"","tabindex":"2","state":errors.length > 0 ? false : null},model:{value:(_vm.updatedTransfer.pickupTime),callback:function ($$v) {_vm.$set(_vm.updatedTransfer, "pickupTime", $$v)},expression:"updatedTransfer.pickupTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pages.airportTransferService.wizard.passengers'),"label-for":("passengers-" + _vm.formId)}},[_c('validation-provider',{attrs:{"name":_vm.$t('pages.airportTransferService.wizard.passengers'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-spinbutton',{staticClass:"mb-2",attrs:{"id":("passengers-" + _vm.formId),"min":"1","max":"23","step":"1","state":errors.length > 0 ? false : null},model:{value:(_vm.updatedTransfer.passengers),callback:function ($$v) {_vm.$set(_vm.updatedTransfer, "passengers", $$v)},expression:"updatedTransfer.passengers"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.flightNumberLabel,"label-for":("flight-number-" + _vm.formId)}},[_c('validation-provider',{attrs:{"name":_vm.$t('pages.airportTransferService.wizard.flightNumber'),"rules":_vm.flightNumberRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("flight-number-" + _vm.formId),"state":errors.length > 0 ? false : null,"maxlength":"10","trim":"","formatter":_vm.flightNumberFormatter},model:{value:(_vm.updatedTransfer.flightNumber),callback:function ($$v) {_vm.$set(_vm.updatedTransfer, "flightNumber", $$v)},expression:"updatedTransfer.flightNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }