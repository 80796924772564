<template>
  <b-overlay
    :show="loading"
    variant="white"
    :opacity="0.9"
  >
    <form-wizard
      id="airport-transfer-wizard"
      color="#ffcd00"
      error-color="#ea5455"
      :title="null"
      :subtitle="null"
      shape="square"
      @on-change="onTabChange"
      @on-error="onValidationError"
      @on-complete="onWizardComplete"
    >
      <template #next>
        <b-button
          class="mb-2"
          variant="primary"
          :disabled="loadingBookings"
        >
          {{ $t('Next') }}
        </b-button>
      </template>

      <template #prev>
        <b-button
          class="mb-2"
          variant="secondary"
        >
          {{ $t('Back') }}
        </b-button>
      </template>

      <template #custom-buttons-left>
        <b-button
          v-if="showChangeBookingButton"
          class="mb-2"
          variant="secondary"
          @click="onChangeBookingClicked"
        >
          {{ $t('Back') }}
        </b-button>
      </template>

      <template #finish>
        <b-button
          class="mb-2"
          variant="primary"
        >
          {{ $t('Book now') }}
          <feather-icon
            class="ml-1"
            icon="ShoppingCartIcon"
          />
        </b-button>
      </template>

      <tab-content
        :title="transfersDetailsTabTitle"
        :before-change="validateTransferDetails"
      >
        <transfers-details-form
          :booking="booking"
          :validation-trigger="transferDetailsValidationTrigger"
          @updated="onTransfersDetailsUpdated"
        />
      </tab-content>

      <tab-content
        :title="clientDetailsTabTitle"
        :before-change="validateClientDetails"
      >
        <client-details-form
          :validation-trigger="clientDetailsValidationTrigger"
          @updated="onClientDetailsUpdated"
        />
      </tab-content>

      <tab-content
        :title="transferSummaryTabTitle"
        :before-change="validateSummaryDetails"
      >
        <transfer-summary
          :validation-trigger="summaryDetailsValidationTrigger"
          :transfers="transfers"
          :booking="booking"
          @updated="onSummaryUpdated"
        />
      </tab-content>
    </form-wizard>
  </b-overlay>
</template>

<script>
import { BOverlay, BButton } from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import TransfersDetailsForm from '@/views/foravila/services/airport-transfer/components/TransfersDetailsForm.vue'
import ClientDetailsForm from '@/views/foravila/services/airport-transfer/components/ClientDetailsForm.vue'
import TransferSummary from '@/views/foravila/services/airport-transfer/components/Summary.vue'
import { notifyError, scrollToId } from '@/utils/methods'
import { AGENCY_GUESTS_INTRANET_LOGIN } from '@appConfig'

export default {
  components: {
    BOverlay,
    BButton,
    FormWizard,
    TabContent,
    TransfersDetailsForm,
    ClientDetailsForm,
    TransferSummary,
  },
  props: {
    booking: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      transfers: null,
      clientDetails: null,
      policyAccepted: false,
      currentTab: 0,

      // ! Defining tab indexes in constants to
      // ! make it easy to change the order
      TRANSFER_DETAILS_TAB: 0,
      PERSONAL_INFO_TAB: 1,
      TRANSFER_SUMMARY_TAB: 2,

      // ! This property has been creater to trigger
      // ! the form validation in the child components
      transferDetailsValidationTrigger: 0,
      clientDetailsValidationTrigger: 0,
      summaryDetailsValidationTrigger: 0,
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters['app/isMobile']
    },
    userData() {
      return JSON.parse(localStorage.getItem('foravilaGuestsUserData'))
    },
    clientUuid() {
      return this.userData.client.uuid
    },
    loadingClient() {
      return this.$store.getters['client/loading']
    },
    client() {
      return this.$store.getters['client/client']
    },
    loadingBookings() {
      return this.$store.getters['bookings/loading']
    },
    currentBookings() {
      return this.$store.getters['bookings/currentBookings']
    },
    showChangeBookingButton() {
      return this.currentTab === this.TRANSFER_DETAILS_TAB
    },
    transfersDetailsTabTitle() {
      return this.isMobile ? '' : this.$t('pages.airportTransferService.wizard.tabs.transfersDetails.title')
    },
    clientDetailsTabTitle() {
      return this.isMobile ? '' : this.$t('pages.airportTransferService.wizard.tabs.clientDetails.title')
    },
    transferSummaryTabTitle() {
      return this.isMobile ? '' : this.$t('pages.airportTransferService.wizard.tabs.summary.title')
    },
    validClientData() {
      if (!this.clientDetails) return false
      return this.clientDetails.valid || false
    },
    validTransfersData() {
      if (!this.transfers) return false
      const { arrivalTransfer, returnTransfer } = this.transfers
      const validArrivalTransfer = arrivalTransfer ? arrivalTransfer.valid : true
      const validReturnTransfer = returnTransfer ? returnTransfer.valid : true
      return validArrivalTransfer && validReturnTransfer
    },
    intranetLink() {
      if (!this.client) return null
      const clientLanguage = this.$i18n.locale || 'en'
      return `${AGENCY_GUESTS_INTRANET_LOGIN}?l=${clientLanguage}`
    },
    transfersRequestData() {
      const transfers = []
      if (this.transfers?.arrivalTransfer) {
        transfers.push(this.getTransferRequestData(this.transfers.arrivalTransfer))
      }
      if (this.transfers?.returnTransfer) {
        transfers.push(this.getTransferRequestData(this.transfers.returnTransfer))
      }

      return {
        booking: this.booking['@id'],
        client: {
          ...this.clientDetails,
          language: this.$i18n.locale,
        },
        transfers,
        intranetLink: this.intranetLink,
      }
    },
  },
  created() {
    if (!this.client && !this.loadingClient) this.fetchClient()
    if (!this.currentBookings.length && !this.loadingBookings) this.fetchBookings()
  },
  methods: {
    fetchClient() {
      this.$store
        .dispatch('client/fetchClient', this.clientUuid)
        .catch(() => notifyError(this.$t('errors.fetchBookings.title'), this.$t('errors.fetchBookings.text')))
    },
    fetchBookings() {
      this.$store
        .dispatch('bookings/fetchBookings', { pagination: false })
        .catch(() => notifyError(this.$t('errors.fetchBookings.title'), this.$t('errors.fetchBookings.text')))
    },
    onTransfersDetailsUpdated(transfersDetails) {
      this.transfers = transfersDetails
    },
    onClientDetailsUpdated(clientDetails) {
      this.clientDetails = clientDetails
    },
    onSummaryUpdated(accepted) {
      this.policyAccepted = accepted
    },
    validateTransferDetails() {
      return new Promise(resolve => {
        if (this.validTransfersData) resolve(true)
        else {
          this.transferDetailsValidationTrigger += 1
          resolve(false)
        }
      })
    },
    validateClientDetails() {
      return new Promise(resolve => {
        if (this.validClientData) resolve(true)
        else {
          this.clientDetailsValidationTrigger += 1
          resolve(false)
        }
      })
    },
    validateSummaryDetails() {
      return new Promise(resolve => {
        if (this.policyAccepted) resolve(true)
        else {
          this.summaryDetailsValidationTrigger += 1
          resolve(false)
        }
      })
    },
    getTransferRequestData(transfer) {
      if (!transfer) return null
      return {
        ...transfer,
        pickupLocation: transfer.pickupLocation['@id'],
        dropoffLocation: transfer.dropoffLocation['@id'],
      }
    },
    onValidationError() {
      if (this.currentTab !== this.TRANSFER_SUMMARY_TAB) {
        scrollToId('airport-transfer-wizard', 90)
      }
    },
    onTabChange(prevIndex, nextIndex) {
      this.currentTab = nextIndex
    },
    onChangeBookingClicked() {
      this.$emit('retry')
    },
    onWizardComplete() {
      this.loading = true
      this.$store
        .dispatch('services/requestAirportTransfer', this.transfersRequestData)
        .then(() => this.$emit('complete', this.booking.localizator))
        .catch(() => this.$emit('complete', false))
        .finally(() => { this.loading = false })
    },
  },
}
</script>

<style lang="scss">
  @import '@foravila-core/scss/vue/libs/vue-wizard.scss';

  #airport-transfer-wizard {
    box-shadow: none;
    margin-bottom: 0;

    .wizard-navigation {
      .wizard-nav {
        li {
          .wizard-icon-circle {
            border-radius: 0;
            .wizard-icon-container {
              border-radius: 0;
            }
            &.checked {
                box-shadow: none;
            }
          }
        }
      }
    }
  }
</style>
