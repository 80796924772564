<template>
  <div>
    <template v-if="!selectedBooking">
      <hero-card />

      <booking-selector
        v-if="loadingBookings || currentBookings.length"
        class="mt-7"
        @selected="onBookingSelected"
        @contact="onContactForBooking"
      />
      <how-it-works />
      <highlights-card />
      <foravila-guarantee />
    </template>

    <airport-transfer-form v-else :booking="selectedBooking" @retry="onRetry" />

    <b-modal
      id="request-transfer-modal"
      :title="$t('pages.airportTransferService.requestModal.title')"
      centered
      size="lg"
      hide-footer
    >
      <p class="mb-3">
        {{ $t("pages.airportTransferService.requestModal.description") }}
      </p>
      <request-transfer-form
        :booking="contactBooking"
        @cancel="onRequestTransferCancel"
        @requested="onTransferRequested"
        @error="onRequestTransferError"
      />
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import HeroCard from "@/views/foravila/services/airport-transfer/components/HeroCard.vue";
import HowItWorks from "@/views/foravila/services/airport-transfer/components/HowItWorks.vue";
import HighlightsCard from "@/views/foravila/services/airport-transfer/components/HighlightsCard.vue";
import BookingSelector from "@/views/foravila/services/airport-transfer/components/BookingSelector.vue";
import AirportTransferForm from "@/views/foravila/services/airport-transfer/components/AirportTransferForm.vue";
import RequestTransferForm from "@/views/foravila/services/airport-transfer/components/RequestTransferForm.vue";
import ForavilaGuarantee from "@/views/foravila/services/components/ForavilaGuarantee.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    HeroCard,
    HowItWorks,
    HighlightsCard,
    BookingSelector,
    AirportTransferForm,
    BModal,
    RequestTransferForm,
    ForavilaGuarantee
  },
  data() {
    return {
      selectedBooking: null,
      contactBooking: null,
      message: null,
      requestFormErrors: 0,
      transfers: []
    };
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem("foravilaGuestsUserData"));
    },
    clientUuid() {
      return this.userData.client.uuid;
    },
    loadingClient() {
      return this.$store.getters["client/loading"];
    },
    client() {
      return this.$store.getters["client/client"];
    },
    loadingBookings() {
      return this.$store.getters["bookings/loading"];
    },
    currentBookings() {
      return this.$store.getters["bookings/currentBookings"];
    }
  },
  created() {
    if (!this.client && !this.loadingClient) this.fetchClient();
    if (!this.currentBookings.length && !this.loadingBookings)
      this.fetchBookings();
  },
  methods: {
    fetchClient() {
      this.$store
        .dispatch("client/fetchClient", this.clientUuid)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.text")
          )
        );
    },
    fetchBookings() {
      this.$store
        .dispatch("bookings/fetchBookings", { pagination: false })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.text")
          )
        );
    },
    onBookingSelected(booking) {
      this.selectedBooking = booking;
    },
    onRetry() {
      this.selectedBooking = null;
    },
    onContactForBooking(booking) {
      this.contactBooking = booking;
      this.$bvModal.show("request-transfer-modal");
    },
    onRequestTransferCancel() {
      this.$bvModal.hide("request-transfer-modal");
    },
    onTransferRequested() {
      this.$bvModal.hide("request-transfer-modal");
      this.$swal({
        icon: "success",
        title: this.$t(
          "pages.airportTransferService.requestModal.successTitle"
        ),
        text: this.$t("pages.airportTransferService.requestModal.successText"),
        customClass: {
          confirmButton: "btn btn-success"
        }
      });
    },
    onRequestTransferError() {
      this.$swal({
        icon: "error",
        title: this.$t("errors.requestTransfer.title"),
        text: this.$t("errors.requestTransfer.text"),
        customClass: {
          confirmButton: "btn btn-danger"
        }
      });
    }
  }
};
</script>
