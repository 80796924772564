<template>
  <div class="airport-transfer-confirmation-card p-5 d-flex flex-column align-items-center">
    <b-img
      src="@/assets/foravila/images/illustrations/airport-transfer-confirmation.svg"
      alt=""
    />
    <h1 class="text-center mt-3">
      {{ $t('pages.airportTransferService.bookingConfirmation.title') }}
    </h1>
    <p class="text-center">
      {{ $t('pages.airportTransferService.bookingConfirmation.subtitle') }}
    </p>
    <div class="d-flex justify-content-between flex-column flex-md-row">
      <b-button
        v-if="localizator"
        variant="primary"
        :to="{ name: 'foravila-booking', params: { localizator: localizator}}"
        class="mr-0 mr-md-3 mb-3 mb-md-0"
      >
        {{ $t('pages.airportTransferService.bookingConfirmation.button') }}
      </b-button>
      <b-button
        variant="secondary"
        @click="tryAgain"
      >
        {{ $t('pages.airportTransferService.bookingConfirmation.newTransfer') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BImg, BButton } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BButton,
  },
  props: {
    localizator: {
      type: String,
      default: null,
    },
  },
  methods: {
    tryAgain() {
      this.$emit('retry')
    },
  },
}
</script>

<style lang="scss">
.airport-transfer-confirmation-card {
  img {
    max-width: 600px;
  }
}
</style>
