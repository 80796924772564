<template>
  <b-form>
    <b-row>
      <b-col cols="12" md="6" class="mb-3 mb-sm-0">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.pickupDate')"
          label-for="pickupDate"
        >
          <b-form-datepicker
            id="pickupDate"
            v-model="transfer.pickupDate"
            :locale="$i18n.locale"
            :start-weekday="1"
            :show-decade-nav="true"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            }"
            hide-header
            class="w-full mb-2"
            label-no-date-selected=""
            tabindex="1"
            :min="today"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6" class="mb-3 mb-sm-0">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.pickupTime')"
          label-for="pickupTime"
        >
          <b-form-timepicker
            id="pickupTime"
            v-model="transfer.pickupTime"
            label-no-time-selected=""
            :locale="timepickerLocale"
            minutes-step="10"
            hide-header
            no-close-button
            class="mb-0"
            tabindex="2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.passengers')"
          label-for="passengers"
        >
          <b-form-spinbutton
            id="passengers"
            v-model="transfer.passengers"
            min="1"
            max="23"
            step="1"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.flightNumber')"
          label-for="flightNumber"
        >
          <b-form-input
            id="flightNumber"
            v-model="transfer.flightNumber"
            maxlength="10"
            trim
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-divider size="xl" />
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.boosterSeat')"
          label-for="booster-seats"
        >
          <b-form-spinbutton
            id="booster-seats"
            v-model="extras.boosterSeats"
            min="0"
            max="10"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.babyTrolleyFree')"
          label-for="baby-trolleys"
        >
          <b-form-spinbutton
            id="baby-trolleys"
            v-model="extras.babyTrolleys"
            min="0"
            max="10"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.maxiCosi')"
          label-for="maxi-cosis"
        >
          <b-form-spinbutton
            id="maxi-cosis"
            v-model="extras.maxiCosis"
            min="0"
            max="10"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.babySeat')"
          label-for="baby-seats"
        >
          <b-form-spinbutton
            id="baby-seats"
            v-model="extras.babySeats"
            min="0"
            max="10"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.bicycle')"
          label-for="bicycles"
        >
          <b-form-spinbutton
            id="bicycles"
            v-model="extras.bicycles"
            min="0"
            max="10"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="6">
        <b-form-group
          :label="$t('pages.airportTransferService.wizard.golfSet')"
          label-for="golfSet"
        >
          <b-form-spinbutton
            id="golfSet"
            v-model="extras.golfSets"
            min="0"
            max="10"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-divider size="xl" />
      </b-col>

      <b-col cols="12">
        <b-form-group
          :label="$t('pages.airportTransferService.requestModal.comments')"
          label-for="golfSet"
        >
          <b-form-textarea
            v-model="comments"
            :placeholder="
              $t(
                'pages.airportTransferService.requestModal.commentsPlaceholder'
              )
            "
            rows="2"
            no-resize
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <div class="d-flex justify-content-start align-items-center my-4">
          <h4 class="mr-2">
            {{
              this.$t(
                "pages.airportTransferService.wizard.tabs.transfersDetails.price.title"
              )
            }}
          </h4>
          <h3>
            {{ totalPriceText }}
          </h3>
        </div>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="secondary" @click="cancel()">
          {{ $t("Cancel") }}
        </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          :disabled="!canSend"
          @click="request()"
        >
          {{ $t("pages.airportTransferService.requestModal.button") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormTimepicker,
  BFormDatepicker,
  BButton,
  BFormTextarea
} from "bootstrap-vue";
import {
  formatCurrency,
  formatDateStringToDatabaseDate,
  formatDateStringToDate
} from "@/utils/formatters";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import transferRates from "@transfer-rates";
import axios from "@/axios";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
    BFormTimepicker,
    BFormDatepicker,
    BDivider,
    BButton,
    BFormTextarea
  },
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      transfer: {
        pickupDate: null,
        pickupTime: null,
        passengers: null,
        flightNumber: null
      },
      extras: {
        boosterSeats: 0,
        babyTrolleys: 0,
        maxiCosis: 0,
        babySeats: 0,
        bicycles: 0,
        golfSets: 0
      },
      comments: null
    };
  },
  computed: {
    today() {
      return this.$moment()
        .startOf("day")
        .toDate();
    },
    accommodationLocation() {
      return this.booking?.accommodation?.location || null;
    },
    transferExtrasRates() {
      return transferRates.extras;
    },
    transferCityRates() {
      if (!this.accommodationLocation) return null;
      let rate = transferRates.cities.find(
        city => city.slug === this.accommodationLocation.citySlug
      );
      if (!rate) rate = transferRates.base;
      return rate;
    },
    transferPrice() {
      if (!this.transferCityRates) return null;
      return this.getPriceByPassengers(
        this.transferCityRates,
        this.transfer.passengers
      );
    },
    transferExtrasPrice() {
      if (!transferRates?.extras) return null;
      if (!this.extras) return 0;

      let price = 0;

      if (this.extras.boosterSeats > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BOOSTER_SEAT"
        );
        price += extra ? extra.price * this.extras.boosterSeats : 0;
      }
      if (this.extras.babyTrolleys > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BABY_TROLLEY"
        );
        price += extra ? extra.price * this.extras.babyTrolleys : 0;
      }
      if (this.extras.maxiCosis > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "MAXI_COSI"
        );
        price += extra ? extra.price * this.extras.maxiCosis : 0;
      }
      if (this.extras.babySeats > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BABY_SEAT"
        );
        price += extra ? extra.price * this.extras.babySeats : 0;
      }
      if (this.extras.bicycles > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BICYCLE"
        );
        price += extra ? extra.price * this.extras.bicycles : 0;
      }
      if (this.extras.golfSets > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "GOLF_SET"
        );
        price += extra ? extra.price * this.extras.golfSets : 0;
      }

      return price;
    },
    totalPrice() {
      if (!this.transferCityRates || !transferRates?.extras) return null;
      return this.transferPrice + (this.transferExtrasPrice || 0);
    },
    totalPriceText() {
      return formatCurrency(this.totalPrice);
    },
    client() {
      return this.$store.getters["client/client"];
    },
    timepickerLocale() {
      if (!this.client) return "en";
      if (this.client.nationality === "ES") return "es";
      return "en";
    },
    subject() {
      return `Sol·licitud LAST MINUTE de transfer per la reserva ${this.booking?.localizator}`;
    },
    requestContent() {
      return {
        locale: this.$i18n.locale,
        subject: this.subject,
        accommodationName: this.booking?.accommodation?.name,
        localizator: this.booking?.localizator,
        transfer: {
          ...this.transfer,
          pickupDate: formatDateStringToDate(this.transfer?.pickupDate) || null,
          pickupTime: this.transfer?.pickupTime
            ? this.transfer.pickupTime.substring(0, 5)
            : null
        },
        extras: this.extras,
        comments: this.comments,
        basePrice: formatCurrency(this.transferPrice),
        extrasPrice: formatCurrency(this.transferExtrasPrice),
        pvpPrice: formatCurrency(this.totalPrice)
      };
    },
    canSend() {
      return (
        this.transfer.pickupDate &&
        this.transfer.pickupTime &&
        this.transfer.passengers &&
        this.transfer.flightNumber
      );
    }
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      if (!this.booking) return;

      // Only set the pickup date if it is same or after today
      if (
        this.$moment(this.booking.checkin).isSameOrAfter(this.$moment(), "day")
      ) {
        this.transfer.pickupDate = formatDateStringToDatabaseDate(
          this.booking.checkin
        );
      }

      // Set the initial passengers amount
      this.transfer.passengers =
        (this.booking?.adults || 0) +
        (this.booking?.children || 0) +
        (this.booking?.babies || 0);
    },
    getPriceByPassengers(rates, passengers) {
      if (!rates || !passengers) return null;
      switch (true) {
        case passengers >= 1 && passengers <= 4:
          return rates.oneToFour;
        case passengers >= 5 && passengers <= 6:
          return rates.fiveToSix;
        case passengers >= 7 && passengers <= 8:
          return rates.sevenToEight;
        case passengers >= 9 && passengers <= 12:
          return rates.nineToTwelve;
        case passengers >= 13 && passengers <= 16:
          return rates.thirteenToSixteen;
        default:
          return rates.seventeenToTwentithree || null;
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    request() {
      this.$store.dispatch("app/setLoading", true);
      axios
        .post(
          `/mail/agency/transfer-request/${this.requestContent.locale}`,
          this.requestContent
        )
        .then(() => this.$emit("requested"))
        .catch(() => this.$emit("error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    }
  }
};
</script>
