<template>
  <div class="airport-transfer-error-card p-5 d-flex flex-column align-items-center">
    <b-img
      src="@/assets/foravila/images/illustrations/airport-transfer-confirmation.svg"
      alt=""
    />
    <h1 class="text-center mt-3">
      {{ $t('pages.airportTransferService.bookingError.title') }}
    </h1>
    <p class="text-center">
      {{ $t('pages.airportTransferService.bookingError.subtitle') }}
    </p>
    <b-button
      variant="primary"
      @click="tryAgain"
    >
      {{ $t('pages.airportTransferService.bookingError.button') }}
    </b-button>
  </div>
</template>

<script>
import { BImg, BButton } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BButton,
  },
  methods: {
    tryAgain() {
      this.$emit('retry')
    },
  },
}
</script>

<style lang="scss">
.airport-transfer-error-card {
  img {
    max-width: 600px;
  }
}
</style>
