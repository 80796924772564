const transferRates = {
  extras: [
    {
      code: "BOOSTER_SEAT",
      price: 300
    },
    {
      code: "BABY_TROLLEY",
      price: 0
    },
    {
      code: "MAXI_COSI",
      price: 500
    },
    {
      code: "BABY_SEAT",
      price: 500
    },
    {
      code: "BICYCLE",
      price: 700
    },
    {
      code: "GOLF_SET",
      price: 500
    }
  ],
  cities: [
    {
      slug: "alaro",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13500,
      thirteenToSixteen: 15500,
      seventeenToTwentithree: 17500
    },
    // ALCUDIA
    {
      slug: "alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "puerto-de-alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "port-dalcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "alcanada",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "alcanada-alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "alcudia-alcanada",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "alcudia-bon-aire",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "cielo-de-bonaire-alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "alcudia-cielo-de-bonaire",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "mal-pas-bon-aire-alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "es-barcares-alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "alcudia-es-barcares",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "manresa-alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "sa-marina-alcudia",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "alcudia-sa-marina",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    // ALGAIDA
    {
      slug: "algaida",
      oneToFour: 8000,
      fiveToSix: 10000,
      sevenToEight: 12500,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 17000
    },
    // ANDRATX
    {
      slug: "andratx",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "port-dandratx",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "ariany",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    // ARTA
    {
      slug: "arta",
      oneToFour: 9500,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "cala-ratjada",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "cala-ratjada-arta",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "colonia-de-sant-pere",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "colonia-de-sant-pere-arta",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "betlem",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "betlem-arta",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // BANYALBUFAR
    {
      slug: "banyalbufar",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "binissalem",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "buger",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "bunyola",
      oneToFour: 8000,
      fiveToSix: 11000,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    // CALVIA
    {
      slug: "calvia",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "peguera-calvia",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "santa-ponca",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "santa-ponca-calvia",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "santa-ponsa",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    // CAMPANET
    {
      slug: "campanet",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    // CAMPOS
    {
      slug: "campos",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // CAPDEPERA
    {
      slug: "capdepera",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "consell",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "costitx",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "deia",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "escorca",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "esporles",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "estellencs",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // FELANITX
    {
      slug: "felanitx",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "portocolom-felanitx",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "portocolom",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // FORNALUTX
    {
      slug: "fornalutx",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "inca",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "lloret-de-vistalegre",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "lloseta",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "llubi",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    // LLUCMAJOR
    {
      slug: "llucmajor",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "cala-blava",
      oneToFour: 5000,
      fiveToSix: 7000,
      sevenToEight: 8500,
      nineToTwelve: 10000,
      thirteenToSixteen: 12000,
      seventeenToTwentithree: 14000
    },
    {
      slug: "cala-blava-llucmajor",
      oneToFour: 5000,
      fiveToSix: 7000,
      sevenToEight: 8500,
      nineToTwelve: 10000,
      thirteenToSixteen: 12000,
      seventeenToTwentithree: 14000
    },
    // MANACOR
    {
      slug: "manacor",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "manacor-porto-cristo",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "porto-cristo-manacor",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "sillot-manacor",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // MANCOR DE LA VALL
    {
      slug: "mancor-de-la-vall",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13500,
      thirteenToSixteen: 15500,
      seventeenToTwentithree: 17500
    },
    {
      slug: "maria-de-la-salut",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "marratxi",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "montuiri",
      oneToFour: 8000,
      fiveToSix: 10000,
      sevenToEight: 12500,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 17000
    },
    // MURO
    {
      slug: "muro",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "playa-de-muro",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "muro-platja-de-muro",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    // PALMA
    {
      slug: "palma-de-mallorca",
      oneToFour: 5500,
      fiveToSix: 7000,
      sevenToEight: 8500,
      nineToTwelve: 10000,
      thirteenToSixteen: 12000,
      seventeenToTwentithree: 14000
    },
    {
      slug: "palma",
      oneToFour: 5500,
      fiveToSix: 7000,
      sevenToEight: 8500,
      nineToTwelve: 10000,
      thirteenToSixteen: 12000,
      seventeenToTwentithree: 14000
    },
    // PETRA
    {
      slug: "petra",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    // POLLENÇA
    {
      slug: "pollensa",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "pollenca",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "puerto-de-pollensa",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "formentor",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    },
    {
      slug: "pollenca-formentor",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    },
    {
      slug: "formentor-pollensa",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    },
    {
      slug: "cala-sant-vicenc",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "cala-sant-vicenc-pollensa",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    // PORRERES
    {
      slug: "porreres",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 13000,
      nineToTwelve: 14500,
      thirteenToSixteen: 16500,
      seventeenToTwentithree: 17500
    },
    {
      slug: "puigpunyent",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "sa-pobla",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "sa-pobla-crestatx",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "crestatx-sa-pobla",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "sant-joan",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    // SANT LLORENÇ DES CARDASSAR
    {
      slug: "sant-llorenc-des-cardassar",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "sant-llorens-des-cardassar",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "sa-coma",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "sa-coma-sant-llorenc-des-cardassar",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // SANTA EUGÈNIA
    {
      slug: "santa-eugenia",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    // SANTA MARGALIDA
    {
      slug: "santa-margalida",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "can-picafort",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "can-picafort-santa-margalida",
      oneToFour: 8500,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "son-serra-de-marina",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "son-serra-de-marina-santa-margalida",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // SANTA MARIA
    {
      slug: "santa-maria",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "santa-maria-del-cami",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "santanyi",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // SELVA
    {
      slug: "selva",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "moscari",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "moscari-selva",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "caimari",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "caimari-selva",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "biniamar",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13500,
      thirteenToSixteen: 15500,
      seventeenToTwentithree: 17500
    },
    {
      slug: "biniamar-selva",
      oneToFour: 7500,
      fiveToSix: 10000,
      sevenToEight: 11500,
      nineToTwelve: 13500,
      thirteenToSixteen: 15500,
      seventeenToTwentithree: 17500
    },
    // SENCELLES
    {
      slug: "sencelles",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    // SES SALINES
    {
      slug: "ses-salines",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "colonia-de-sant-jordi",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "colonia-de-sant-jordi-ses-salines",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    // SINEU
    {
      slug: "sineu",
      oneToFour: 7000,
      fiveToSix: 9500,
      sevenToEight: 11000,
      nineToTwelve: 13000,
      thirteenToSixteen: 15000,
      seventeenToTwentithree: 17000
    },
    {
      slug: "soller",
      oneToFour: 8000,
      fiveToSix: 11000,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "son-servera",
      oneToFour: 11000,
      fiveToSix: 13000,
      sevenToEight: 15000,
      nineToTwelve: 17000,
      thirteenToSixteen: 19000,
      seventeenToTwentithree: 21000
    },
    {
      slug: "valldemossa",
      oneToFour: 8000,
      fiveToSix: 10500,
      sevenToEight: 12000,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 18000
    },
    {
      slug: "vilafranca-de-bonany",
      oneToFour: 8000,
      fiveToSix: 10000,
      sevenToEight: 12500,
      nineToTwelve: 14000,
      thirteenToSixteen: 16000,
      seventeenToTwentithree: 17000
    }
  ],
  areas: [
    {
      name: "north",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    },
    {
      name: "south",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    },
    {
      name: "east",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    },
    {
      name: "west",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    },
    {
      name: "center",
      oneToFour: 10500,
      fiveToSix: 13000,
      sevenToEight: 14500,
      nineToTwelve: 16500,
      thirteenToSixteen: 18500,
      seventeenToTwentithree: 20500
    }
  ],
  base: {
    oneToFour: 11000,
    fiveToSix: 13000,
    sevenToEight: 15000,
    nineToTwelve: 17000,
    thirteenToSixteen: 19000,
    seventeenToTwentithree: 21000
  }
};

export default transferRates;
