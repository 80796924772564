<template>
  <validation-observer :ref="`clientFormRules${formId}`">
    <b-form :id="`airport-transfer-form-${formId}`">
      <b-row>
        <b-col cols="12" md="4" class="mb-3">
          <b-row>
            <b-col v-if="pickupLocationText" cols="12" sm="6" md="12">
              <p class="mb-0">
                {{
                  this.$t("pages.airportTransferService.wizard.pickupLocation")
                }}
              </p>
              <h5 class="font-weight-bold mb-3">
                {{ pickupLocationText }}
              </h5>
            </b-col>
            <b-col v-if="dropoffLocationText" cols="12" sm="6" md="12">
              <p class="mb-0">
                {{
                  this.$t("pages.airportTransferService.wizard.dropoffLocation")
                }}
              </p>
              <h5 class="font-weight-bold">
                {{ dropoffLocationText }}
              </h5>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" md="8">
          <b-row>
            <b-col cols="12" md="6" class="mb-3 mb-sm-0">
              <b-form-group
                :label="$t('pages.airportTransferService.wizard.pickupDate')"
                :label-for="`pickup-date-${formId}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('pages.airportTransferService.wizard.pickupDate')"
                  rules="required"
                >
                  <b-form-datepicker
                    :id="`pickup-date-${formId}`"
                    v-model="updatedTransfer.pickupDate"
                    :locale="$i18n.locale"
                    :start-weekday="1"
                    :show-decade-nav="true"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }"
                    hide-header
                    class="w-full mb-2"
                    label-no-date-selected=""
                    tabindex="1"
                    :state="errors.length > 0 ? false : null"
                    :min="tomorrow"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" class="mb-3 mb-sm-0">
              <b-form-group
                :label="$t('pages.airportTransferService.wizard.pickupTime')"
                :label-for="`pickup-time-${formId}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('pages.airportTransferService.wizard.pickupTime')"
                  rules="required"
                >
                  <b-form-timepicker
                    :id="`pickup-time-${formId}`"
                    v-model="updatedTransfer.pickupTime"
                    label-no-time-selected=""
                    :locale="timepickerLocale"
                    minutes-step="10"
                    hide-header
                    no-close-button
                    class="mb-0"
                    tabindex="2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                :label="$t('pages.airportTransferService.wizard.passengers')"
                :label-for="`passengers-${formId}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('pages.airportTransferService.wizard.passengers')"
                  rules="required"
                >
                  <b-form-spinbutton
                    :id="`passengers-${formId}`"
                    v-model="updatedTransfer.passengers"
                    min="1"
                    max="23"
                    step="1"
                    class="mb-2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group
                :label="flightNumberLabel"
                :label-for="`flight-number-${formId}`"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('pages.airportTransferService.wizard.flightNumber')"
                  :rules="flightNumberRules"
                >
                  <b-form-input
                    :id="`flight-number-${formId}`"
                    v-model="updatedTransfer.flightNumber"
                    :state="errors.length > 0 ? false : null"
                    maxlength="10"
                    trim
                    :formatter="flightNumberFormatter"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSpinbutton,
  BFormTimepicker,
  BFormDatepicker
} from "bootstrap-vue";
import { formatDateStringToDatabaseDate } from "@/utils/formatters";
import { v4 as uuidv4 } from "uuid";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@foravila-validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
    BFormTimepicker,
    BFormDatepicker
  },
  props: {
    pickupLocation: {
      type: [Object, null],
      default: null
    },
    pickupLocationAirport: {
      type: Boolean,
      default: false
    },
    dropoffLocation: {
      type: [Object, null],
      default: null
    },
    dropoffLocationAirport: {
      type: Boolean,
      default: false
    },
    transfer: {
      type: [Object, null],
      default: null
    },
    transferType: {
      type: [String, null],
      default: null
    },
    validationTrigger: {
      type: Number,
      default: null
    },
    requiredFlightNumber: {
      type: Boolean,
      default: true
    },
    accommodationName: {
      type: [String, null],
      default: null
    }
  },
  data() {
    return {
      updatedTransfer: {
        pickupLocation: this.pickupLocation,
        dropoffLocation: this.dropoffLocation,
        pickupDate: null,
        pickupTime: null,
        passengers: null,
        flightNumber: null,
        valid: false
      },
      timepickerFormat: "HH:mm:ss",
      formId: null,
      required
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    client() {
      return this.$store.getters["client/client"];
    },
    timepickerLocale() {
      if (!this.client) return "en";
      if (this.client.nationality === "ES") return "es";
      return "en";
    },
    emptyForm() {
      return (
        !this.updatedTransfer.pickupDate &&
        !this.updatedTransfer.pickupTime &&
        !this.updatedTransfer.passengers &&
        !this.updatedTransfer.flightNumber
      );
    },
    flightNumberRules() {
      if (!this.requiredFlightNumber) return "";
      return "required";
    },
    flightNumberLabel() {
      if (!this.requiredFlightNumber)
        return this.$t(
          "pages.airportTransferService.wizard.flightNumberOptional"
        );
      return this.$t("pages.airportTransferService.wizard.flightNumber");
    },
    pickupLocationCity() {
      return this.pickupLocation?.city || null;
    },
    pickupLocationRegionSlug() {
      return this.pickupLocation?.regionSlug || null;
    },
    pickupLocationText() {
      if (
        this.pickupLocationAirport &&
        this.pickupLocationRegionSlug === "mallorca"
      ) {
        return this.$t("pages.airportTransferService.palmaAirportPmi");
      }
      const text = [];
      if (this.accommodationName) {
        text.push(`${this.accommodationName}`);
      }
      if (this.pickupLocationCity) {
        text.push(this.$t("pages.airportTransferService.wizard.in"));
        text.push(this.pickupLocationCity);
      }
      return text.length ? text.join(" ") : null;
    },
    dropoffLocationCity() {
      return this.dropoffLocation?.city || null;
    },
    dropoffLocationRegionSlug() {
      return this.dropoffLocation?.regionSlug || null;
    },
    dropoffLocationText() {
      if (
        this.dropoffLocationAirport &&
        this.dropoffLocationRegionSlug === "mallorca"
      ) {
        return this.$t("pages.airportTransferService.palmaAirportPmi");
      }
      const text = [];
      if (this.accommodationName) {
        text.push(`${this.accommodationName}`);
        text.push(this.$t("pages.airportTransferService.wizard.in"));
      }
      if (this.dropoffLocationCity) text.push(this.dropoffLocationCity);
      return text.length ? text.join(" ") : null;
    },
    tomorrow() {
      return this.$moment()
        .add(1, "d")
        .toDate();
    }
  },
  watch: {
    transfer(transfer) {
      if (!transfer) return;
      this.initInputs();
    },
    pickupLocation(location) {
      this.updatedTransfer.pickupLocation = location;
    },
    dropoffLocation(location) {
      this.updatedTransfer.dropoffLocation = location;
    },
    updatedTransfer: {
      handler() {
        this.validateFormRules(true)
          .then(() => this.$emit("updated", this.updatedTransfer))
          .catch(() => {});
      },
      deep: true
    },
    currentLanguage() {
      // ! we use nextTick to allow vee-validate to update the validation messages
      // TODO: Working but improvement needed
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    },
    validationTrigger() {
      this.validateFormRules();
    }
  },
  mounted() {
    this.formId = uuidv4();
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage);
      if (!this.emptyForm) this.validateFormRules();
    },
    initInputs() {
      if (!this.transfer) return;

      // Only set the pickup date if it is after today
      if (
        this.$moment(this.transfer.pickupDate).isSameOrAfter(
          this.$moment(),
          "day"
        )
      ) {
        this.updatedTransfer.pickupDate = formatDateStringToDatabaseDate(
          this.transfer.pickupDate
        );
      }
      this.updatedTransfer.passengers = this.transfer.passengers;
    },
    flightNumberFormatter(value) {
      return value.toUpperCase();
    },
    validateFormRules(silent = false) {
      return new Promise((resolve, reject) => {
        if (!this.$refs[`clientFormRules${this.formId}`]) reject();
        this.$refs[`clientFormRules${this.formId}`]
          .validate({ silent })
          .then(valid => {
            this.updatedTransfer.valid = valid;
            resolve();
          })
          .catch(() => reject());
      });
    }
  }
};
</script>
