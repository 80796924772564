var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isMobile)?_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('h4',[_vm._v(" "+_vm._s(this.$t('pages.airportTransferService.wizard.tabs.clientDetails.title'))+" ")])])],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-observer',{ref:"personalInformationFormRules"},[_c('b-form',{staticClass:"pb-5",attrs:{"id":"personal-information-form"}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('First name'),"label-for":"firstName"}},[_c('validation-provider',{attrs:{"name":_vm.$t('First name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"firstName","autocomplete":"given-name","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.personalInfo.firstName),callback:function ($$v) {_vm.$set(_vm.personalInfo, "firstName", $$v)},expression:"personalInfo.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Last name'),"label-for":"lastName"}},[_c('b-form-input',{attrs:{"id":"lastName","autocomplete":"family-name","trim":""},model:{value:(_vm.personalInfo.lastName),callback:function ($$v) {_vm.$set(_vm.personalInfo, "lastName", $$v)},expression:"personalInfo.lastName"}})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","autocomplete":"email","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.personalInfo.email),callback:function ($$v) {_vm.$set(_vm.personalInfo, "email", $$v)},expression:"personalInfo.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Phone number'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"phone","rules":("required|phone:" + _vm.validPhone)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-phone-number-input',{staticClass:"phone-number-input",attrs:{"clearable":"","default-country-code":_vm.phoneCountry,"border-radius":0,"show-code-on-list":"","translations":_vm.phoneNumberInputTranslations,"color":"#d8d6de","error-color":"#ea5455","valid-color":"#d8d6de","error":errors.length > 0},on:{"update":_vm.onPhoneUpdated},scopedSlots:_vm._u([{key:"arrow",fn:function(){return [_c('div',[_c('svg',{staticClass:"feather feather-chevron-down open-indicator vs__open-indicator",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15px","height":"15px","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","role":"presentation"}},[_c('polyline',{attrs:{"points":"6 9 12 15 18 9"}})])])]},proxy:true}],null,true),model:{value:(_vm.inputPhone),callback:function ($$v) {_vm.inputPhone=$$v},expression:"inputPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ID type'),"label-for":"idType"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ID type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-full mb-2",class:{'v-select-invalid': errors.length > 0},attrs:{"id":"idType","options":_vm.idTypeOptions,"reduce":function (option) { return option.value; },"state":errors.length > 0 ? false : null,"clearable":false},model:{value:(_vm.personalInfo.idType),callback:function ($$v) {_vm.$set(_vm.personalInfo, "idType", $$v)},expression:"personalInfo.idType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ID number'),"label-for":"idNumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ID number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"idNumber","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.personalInfo.idNumber),callback:function ($$v) {_vm.$set(_vm.personalInfo, "idNumber", $$v)},expression:"personalInfo.idNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }