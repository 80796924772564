<template>
  <div>
    <b-row
      v-if="isMobile"
      class="mb-3"
    >
      <b-col>
        <h4>
          {{ this.$t('pages.airportTransferService.wizard.tabs.clientDetails.title') }}
        </h4>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <validation-observer ref="personalInformationFormRules">
          <b-form
            id="personal-information-form"
            class="pb-5"
          >
            <b-row class="mb-3">
              <b-col
                cols="12"
                md="6"
                class="mb-2"
              >
                <b-form-group
                  :label="$t('First name')"
                  label-for="firstName"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('First name')"
                    rules="required"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="personalInfo.firstName"
                      autocomplete="given-name"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
                class="mb-2"
              >
                <b-form-group
                  :label="$t('Last name')"
                  label-for="lastName"
                >
                  <b-form-input
                    id="lastName"
                    v-model="personalInfo.lastName"
                    autocomplete="family-name"
                    trim
                  />
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
                class="mb-2"
              >
                <b-form-group
                  :label="$t('Email')"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Email')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="personalInfo.email"
                      type="email"
                      autocomplete="email"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
                class="mb-2"
              >
                <b-form-group
                  :label="$t('Phone number')"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    :rules="`required|phone:${validPhone}`"
                  >
                    <vue-phone-number-input
                      v-model="inputPhone"
                      clearable
                      :default-country-code="phoneCountry"
                      :border-radius="0"
                      show-code-on-list
                      :translations="phoneNumberInputTranslations"
                      color="#d8d6de"
                      class="phone-number-input"
                      error-color="#ea5455"
                      valid-color="#d8d6de"
                      :error="errors.length > 0"
                      @update="onPhoneUpdated"
                    >
                      <template #arrow>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15px"
                            height="15px"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-chevron-down open-indicator vs__open-indicator"
                            role="presentation"
                          >
                            <polyline points="6 9 12 15 18 9" />
                          </svg>
                        </div>
                      </template>
                    </vue-phone-number-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('ID type')"
                  label-for="idType"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('ID type')"
                    rules="required"
                  >
                    <v-select
                      id="idType"
                      v-model="personalInfo.idType"
                      :options="idTypeOptions"
                      :reduce="option => option.value"
                      class="w-full mb-2"
                      :class="{'v-select-invalid': errors.length > 0}"
                      :state="errors.length > 0 ? false : null"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  :label="$t('ID number')"
                  label-for="idNumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('ID number')"
                    rules="required"
                  >
                    <b-form-input
                      id="idNumber"
                      v-model="personalInfo.idNumber"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email, phone } from '@foravila-validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { idTypeOptions } from '@/utils/select-options'
import { getCountryCodeFromDialCode } from '@/utils/methods'

export default {
  components: {
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
    VuePhoneNumberInput,
  },
  props: {
    validationTrigger: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      personalInfo: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        idType: 'PASSPORT',
        idNumber: null,
        valid: false,
      },
      inputPhone: null,
      phoneCountry: null,
      validPhone: null,
      required,
      email,
      phone,
    }
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase()
    },
    client() {
      return this.$store.getters['client/client']
    },
    isMobile() {
      return this.$store.getters['app/isMobile']
    },
    idTypeOptions() {
      return idTypeOptions(this.$i18n.locale)
    },
    emptyForm() {
      return !this.personalInfo.firstName
        && !this.personalInfo.lastName
        && !this.personalInfo.email
        && !this.inputPhone
        && !this.personalInfo.idType
        && !this.personalInfo.idNumber
    },
    phoneNumberInputTranslations() {
      return {
        countrySelectorLabel: this.$t('pages.booking.phoneNumberInput.countrySelectorLabel'),
        countrySelectorError: '',
        phoneNumberLabel: '',
        example: this.$t('pages.booking.phoneNumberInput.example'),
      }
    },
  },
  watch: {
    client() {
      this.initClientData()
    },
    currentLanguage() {
      // ! We use nextTick to allow vee-validate to update the validation messages
      // TODO: Working but improvement needed
      this.$nextTick(() => {
        this.switchValidationLocale()
      })
    },
    personalInfo: {
      handler() {
        this.validateFormRules(true)
          .then(() => this.$emit('updated', this.personalInfo))
          .catch(() => {})
      },
      deep: true,
    },
    validationTrigger() {
      this.validateFormRules()
    },
  },
  mounted() {
    this.$emit('updated', this.personalInfo)
    this.initClientData()
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage)
      if (!this.emptyForm) this.validateFormRules()
    },
    initClientData() {
      if (!this.client) return
      this.personalInfo.firstName = this.client.firstName
      this.personalInfo.lastName = this.client.lastName
      this.phoneCountry = this.client.phoneCountry
      this.personalInfo.email = this.client.email
      this.personalInfo.idType = this.client.idType
      this.personalInfo.idNumber = this.client.idNumber
      this.initPhoneFields()
    },
    initPhoneFields() {
      if (!this.client) return
      const phoneFields = this.client.phone ? this.client.phone.replaceAll('-', '').split(' ') : []
      let phoneNumber = null
      if (phoneFields.length > 1) {
        const dialCode = phoneFields[0] || null
        this.phoneCountry = getCountryCodeFromDialCode(dialCode)
        phoneNumber = phoneFields.slice(1).join('') || null
      } else {
        phoneNumber = phoneFields[0] || null
      }
      this.inputPhone = phoneNumber
      this.personalInfo.phone = this.client.phone
    },
    validateFormRules(silent = false) {
      return new Promise((resolve, reject) => {
        if (!this.$refs.personalInformationFormRules) reject()
        this.$refs.personalInformationFormRules
          .validate({ silent })
          .then(valid => {
            this.personalInfo.valid = valid && this.validPhone
            resolve()
          })
          .catch(() => reject())
      })
    },
    onPhoneUpdated(phoneData) {
      if (this.emptyForm) return
      this.validPhone = phoneData.isValid
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the phone validation
      this.$nextTick(() => {
        this.personalInfo.phone = phoneData.formatInternational || null
      })
    },
  },
}
</script>

<style lang="scss">
#personal-information-form {
  .v-select-invalid > .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .phone-number-input {
    .select-country-container {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100px;
      flex: 0 0 100px;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
    }
    .country-selector,
    .input-tel {
      height: 38px !important;
      min-height: 38px !important;
      font-family: inherit !important;
      input {
        color: #6e6b7b;
        border-color: #d8d6de;
        height: 38px !important;
        min-height: 38px !important;
        padding-top: 2px !important;
        &:hover {
          // border-color: #d8d6de !important;
        }
        &:focus {
          border-color: #ffcd00 !important;
          box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
        }
      }
    }
    .country-selector__country-flag {
      top: 13px !important;
    }
    .country-selector__toggle {
      top: 8px !important
    }
    .country-selector__label, .input-tel__label {
      display: none !important;
    }
    .input-tel__clear:hover {
      color: #6e6b7b;
    }
    .input-tel__clear__effect {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: transparent !important;
      border-radius: 24px;
      -webkit-transform: none;
      transform: none;
      -webkit-transition: none !important;
      transition: none !important;
    }
    .country-selector__toggle:hover > .country-selector input{
      border-color: #d8d6de !important;
    }
    .country-selector__list__item__calling-code {
      color: #6e6b7b;
    }
    .country-selector__list {
      margin: 10px 0 0 0;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    }
    .dots-text {
      color: #6e6b7b;
    }
  }
}
</style>
