<template>
  <div
    class="booking-selector-item mb-3 d-md-flex justify-content-between align-items-center bg-light p-4 border"
  >
    <div>
      <h5 v-if="title" class="mb-1">
        {{ title }}
      </h5>
      <div v-if="dates" class="three-dots">
        <small>{{ dates }}</small>
      </div>
      <p v-if="minRate" class="three-dots mb-0 mt-3">
        {{ $t("pages.airportTransferService.bookingSelector.fromPrice") }}
        <span class="text-primary font-weight-bolder">{{ minRate }}</span>
      </p>
    </div>
    <b-button
      v-if="isBookable"
      variant="primary"
      class="mt-4 mt-md-0"
      :block="isMobile"
      @click="onBookClicked"
    >
      {{ $t("Book now") }}
    </b-button>
    <b-button
      v-else
      variant="primary"
      class="mt-4 mt-md-0"
      :block="isMobile"
      @click="onContactClicked"
    >
      {{ $t("Contact us") }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

import {
  formatCurrency,
  formatDateStringToDayDayShortMonthYear
} from "@/utils/formatters";
import transferRates from "@transfer-rates";

export default {
  components: {
    BButton
  },
  props: {
    booking: {
      type: Object,
      required: true
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
    accommodation() {
      return this.booking?.accommodation || null;
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    accommodationLocation() {
      return this.accommodation?.location || null;
    },
    accommodationRegionSlug() {
      return this.accommodationLocation?.regionSlug || null;
    },
    accommodationCity() {
      return this.accommodationLocation?.city || null;
    },
    airportName() {
      // TODO: keep adding airports if we start working in different regions
      if (this.accommodationRegionSlug === "mallorca") {
        return this.$t("pages.airportTransferService.palmaAirportPmi");
      }
      return null;
    },
    title() {
      const text = [];
      if (this.airportName) {
        text.push(
          `${this.$t(
            "pages.airportTransferService.bookingSelector.fromWithAirportName"
          )} ${this.airportName}`
        );
      } else {
        text.push(
          this.$t(
            "pages.airportTransferService.bookingSelector.fromWithoutAirportName"
          )
        );
      }
      if (this.accommodationName) {
        text.push(
          `${this.$t("pages.airportTransferService.bookingSelector.to")} ${
            this.accommodationName
          }`
        );
        if (this.accommodationCity) {
          text.push(
            `${this.$t("pages.airportTransferService.bookingSelector.in")} ${
              this.accommodationCity
            }`
          );
        }
      }
      return text.length ? text.join(" ") : null;
    },
    checkinDate() {
      return formatDateStringToDayDayShortMonthYear(
        this.booking?.checkin,
        this.$i18n.locale
      );
    },
    checkoutDate() {
      return formatDateStringToDayDayShortMonthYear(
        this.booking?.checkout,
        this.$i18n.locale
      );
    },
    dates() {
      const dates = [];
      if (this.checkinDate) dates.push(this.checkinDate);
      if (this.checkoutDate) dates.push(this.checkoutDate);
      return dates.length ? dates.join(" - ") : null;
    },
    cityRates() {
      if (!this.accommodationLocation?.citySlug) return null;
      let rate = transferRates.cities.find(
        city => city.slug === this.accommodationLocation.citySlug
      );
      if (!rate) rate = transferRates.base;
      return rate;
    },
    minRate() {
      return (
        formatCurrency(this.cityRates?.oneToFour) ||
        formatCurrency(this.cityRates?.fiveToSix) ||
        formatCurrency(this.cityRates?.sevenToEight) ||
        formatCurrency(this.cityRates?.nineToTwelve) ||
        formatCurrency(this.cityRates?.thirteenToSixteen) ||
        formatCurrency(this.cityRates?.seventeenToTwentithree) ||
        null
      );
    },
    isBookable() {
      const today = this.$moment().startOf("day");
      const bookingStartDate = this.$moment(this.booking?.checkin).startOf(
        "day"
      );
      return bookingStartDate.diff(today, "days") > 1;
    }
  },
  methods: {
    onBookClicked() {
      this.$emit("selected", this.booking);
    },
    onContactClicked() {
      this.$emit("contact", this.booking);
    }
  }
};
</script>
