<template>
  <b-card id="airport-transfer-form">
    <transfers-wizard
      v-if="showWizard"
      :booking="booking"
      @complete="onTransferBookResult"
      @retry="onRetry"
    />
    <div id="airport-transfer-result">
      <transfers-booking-confirmation
        v-if="showConfirmation"
        :localizator="bookingLocalizator"
        @retry="onRetry"
      />
      <transfers-booking-error
        v-if="showError"
        @retry="onRetry"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import TransfersWizard from '@/views/foravila/services/airport-transfer/components/TransfersWizard.vue'
import TransfersBookingConfirmation from '@/views/foravila/services/airport-transfer/components/TransfersBookingConfirmation.vue'
import TransfersBookingError from '@/views/foravila/services/airport-transfer/components/TransfersBookingError.vue'
import { notifyError, scrollToId } from '@/utils/methods'

export default {
  components: {
    BCard,
    TransfersWizard,
    TransfersBookingConfirmation,
    TransfersBookingError,
  },
  props: {
    booking: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      showResult: false,
      error: false,
      bookingLocalizator: null,
    }
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('foravilaGuestsUserData'))
    },
    clientUuid() {
      return this.userData.client.uuid
    },
    loadingClient() {
      return this.$store.getters['client/loading']
    },
    client() {
      return this.$store.getters['client/client']
    },
    loadingBookings() {
      return this.$store.getters['bookings/loading']
    },
    currentBookings() {
      return this.$store.getters['bookings/currentBookings']
    },
    showConfirmation() {
      return this.showResult && !this.error
    },
    showError() {
      return this.showResult && this.error
    },
    showWizard() {
      return !this.showResult
    },
  },
  created() {
    if (!this.client && !this.loadingClient) this.fetchClient()
    if (!this.currentBookings.length && !this.loadingBookings) this.fetchBookings()
  },
  methods: {
    fetchBookings() {
      this.$store
        .dispatch('bookings/fetchBookings', { pagination: false })
        .catch(() => notifyError(this.$t('errors.fetchBookings.title'), this.$t('errors.fetchBookings.text')))
    },
    fetchClient() {
      this.$store
        .dispatch('client/fetchClient', this.clientUuid)
        .catch(() => notifyError(this.$t('errors.fetchBookings.title'), this.$t('errors.fetchBookings.text')))
    },
    onTransferBookResult(localizator) {
      this.showResult = true
      this.error = !localizator
      this.bookingLocalizator = localizator
      scrollToId('airport-transfer-result', 90)
    },
    onRetry() {
      this.showResult = false
      this.error = false
      scrollToId('airport-transfer-form', 90)
      this.$emit('retry')
    },
  },
}
</script>

<style lang="scss">
#airport-transfer-form {
    .card-body {
        padding: 0;
    }
}
</style>
