<template>
  <div>
    <div
      class="d-flex justify-content-between align-items-top font-weight-bold"
    >
      <div class="text-primary">
        {{
          $t("pages.airportTransferService.wizard.tabs.summary.transferService")
        }}
      </div>
      <div v-if="price" class="text-primary">
        {{ price }}
      </div>
    </div>

    <div class="d-block d-md-flex justify-content-md-between mt-3">
      <div v-if="pickupLocationText" class="pr-4">
        <div>
          <small>
            {{ this.$t("pages.airportTransferService.wizard.pickupLocation") }}
          </small>
        </div>
        <span class="font-weight-bold">
          {{ pickupLocationText }}
        </span>
      </div>

      <div
        v-if="dropoffLocationText"
        class="text-md-right mt-3 mt-md-0"
        :class="{
          'text-md-right': pickupLocation,
          'text-md-left': !pickupLocation
        }"
      >
        <div>
          <small>
            {{ this.$t("pages.airportTransferService.wizard.dropoffLocation") }}
          </small>
        </div>
        <span class="font-weight-bold">
          {{ dropoffLocationText }}
        </span>
      </div>
    </div>

    <div v-if="pickupDateTime" class="mt-3">
      <div>
        <small>
          {{
            $t(
              "pages.airportTransferService.wizard.tabs.summary.pickupDateTime"
            )
          }}
        </small>
      </div>
      <span class="font-weight-bold">
        {{ pickupDateTime }}
      </span>
    </div>

    <div class="mt-3">
      <div>
        <small>
          {{ $t("Passengers") }}
        </small>
      </div>
      <span class="font-weight-bold">
        {{ passengers }}
      </span>
    </div>

    <div v-if="showDivider" class="dashed-divider my-4" />
  </div>
</template>

<script>
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";

export default {
  props: {
    transfer: {
      type: [Object, null],
      default: null
    },
    hideDivider: {
      type: Boolean,
      default: false
    },
    pickupLocationAirport: {
      type: Boolean,
      default: false
    },
    dropoffLocationAirport: {
      type: Boolean,
      default: false
    },
    accommodationName: {
      type: [String, null],
      default: null
    }
  },
  computed: {
    showDivider() {
      return !this.hideDivider;
    },
    pickupLocation() {
      return this.transfer?.pickupLocation || null;
    },
    pickupLocationCity() {
      return this.pickupLocation?.city || null;
    },
    pickupLocationRegionSlug() {
      return this.pickupLocation?.regionSlug || null;
    },
    pickupLocationText() {
      if (
        this.pickupLocationAirport &&
        this.pickupLocationRegionSlug === "mallorca"
      ) {
        return this.$t("pages.airportTransferService.palmaAirportPmi");
      }
      const text = [];
      if (this.accommodationName) {
        text.push(`${this.accommodationName}`);
        text.push(this.$t("pages.airportTransferService.wizard.in"));
      }
      if (this.pickupLocationCity) text.push(this.pickupLocationCity);
      return text.length ? text.join(" ") : null;
    },
    dropoffLocation() {
      return this.transfer?.dropoffLocation || null;
    },
    dropoffLocationCity() {
      return this.dropoffLocation?.city || null;
    },
    dropoffLocationRegionSlug() {
      return this.dropoffLocation?.regionSlug || null;
    },
    dropoffLocationText() {
      if (
        this.dropoffLocationAirport &&
        this.dropoffLocationRegionSlug === "mallorca"
      ) {
        return this.$t("pages.airportTransferService.palmaAirportPmi");
      }
      const text = [];
      if (this.accommodationName) {
        text.push(`${this.accommodationName}`);
        text.push(this.$t("pages.airportTransferService.wizard.in"));
      }
      if (this.dropoffLocationCity) text.push(this.dropoffLocationCity);
      return text.length ? text.join(" ") : null;
    },
    price() {
      return formatCurrency(this.transfer?.basePrice);
    },
    pickupDateTime() {
      let dateTimeString = [];
      if (this.transfer?.pickupDate)
        dateTimeString.push(this.transfer.pickupDate.split("T")[0]);
      if (this.transfer?.pickupTime)
        dateTimeString.push(this.transfer.pickupTime);

      if (!dateTimeString.length) return null;

      dateTimeString = dateTimeString.join("T");
      const formatting = {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      };

      return (
        formatDateStringToDate(dateTimeString, this.$i18n.locale, formatting) ||
        null
      );
    },
    passengers() {
      return this.transfer?.passengers || null;
    }
  }
};
</script>
