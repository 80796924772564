<template>
  <div class="pb-3">
    <b-row v-if="isMobile" class="mb-3">
      <b-col>
        <h4>
          {{
            this.$t(
              "pages.airportTransferService.wizard.tabs.transfersDetails.title"
            )
          }}
        </h4>
      </b-col>
    </b-row>

    <single-transfer-form
      :pickup-location="arrivalTransferPickupLocation"
      pickup-location-airport
      :dropoff-location="arrivalTransferDropoffLocation"
      :transfer="arrivalTransfer"
      :validation-trigger="validationTrigger"
      :accommodation-name="accommodationName"
      @updated="onArrivalTransferUpdated"
    />

    <b-row v-if="!returnTransfer" class="my-5">
      <b-col cols="12">
        <div
          class="p-5 bg-primary d-sm-flex justify-content-between align-items-center shadow"
        >
          <div class="d-flex align-items-center">
            <feather-icon
              class="d-none d-md-block mr-5"
              icon="RotateCcwIcon"
              size="40"
            />
            <div class="pr-0 pr-sm-4">
              <h4>
                {{
                  this.$t(
                    "pages.airportTransferService.wizard.tabs.transfersDetails.addReturnBanner.title"
                  )
                }}
              </h4>
              <p class="text-dark mb-0">
                {{
                  this.$t(
                    "pages.airportTransferService.wizard.tabs.transfersDetails.addReturnBanner.subtitle"
                  )
                }}
              </p>
            </div>
          </div>
          <div class="mt-4 mt-sm-0">
            <b-button
              variant="outline-dark"
              class="p-4 text-nowrap"
              @click="addReturnTrip"
            >
              {{
                this.$t(
                  "pages.airportTransferService.wizard.tabs.transfersDetails.addReturnBanner.button"
                )
              }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <div v-show="returnTransfer">
      <b-row class="my-5">
        <b-col cols="12" class="position-relative">
          <hr class="w-100" />
          <span class="transfer-form-remove-button bg-white px-3">
            <b-button variant="primary" size="sm" @click="removeReturnTrip">
              {{
                this.$t(
                  "pages.airportTransferService.wizard.tabs.transfersDetails.removeReturn"
                )
              }}
            </b-button>
          </span>
        </b-col>
      </b-row>

      <single-transfer-form
        :pickup-location="returnTransferPickupLocation"
        :dropoff-location="returnTransferDropoffLocation"
        dropoff-location-airport
        :transfer="returnTransfer"
        :validation-trigger="validationTrigger"
        :required-flight-number="false"
        :accommodation-name="accommodationName"
        @updated="onReturnTransferUpdated"
      />

      <b-row class="my-5">
        <b-col cols="12">
          <b-alert variant="warning" show>
            <h4 class="alert-heading d-flex align-items-center">
              <feather-icon
                class="d-none d-md-block mr-3"
                icon="InfoIcon"
                size="30"
              />
              {{
                this.$t(
                  "pages.airportTransferService.wizard.tabs.transfersDetails.returnAlerts.title"
                )
              }}
            </h4>
            <div class="alert-body">
              <p class="mb-2">
                -
                {{
                  this.$t(
                    "pages.airportTransferService.wizard.tabs.transfersDetails.returnAlerts.alert1"
                  )
                }}
              </p>
              <p class="mb-2">
                -
                {{
                  this.$t(
                    "pages.airportTransferService.wizard.tabs.transfersDetails.returnAlerts.alert2"
                  )
                }}
              </p>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </div>

    <hr class="w-100 my-5" />
    <extras-form @updated="onExtrasUpdated" />

    <template v-if="totalPrice">
      <hr class="w-100 my-5" />
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-end align-items-center">
            <h4 class="mr-2">
              {{
                this.$t(
                  "pages.airportTransferService.wizard.tabs.transfersDetails.price.title"
                )
              }}
            </h4>
            <h3>
              {{ totalPriceText }}
            </h3>
          </div>
          <p class="text-right">
            {{
              this.$t(
                "pages.airportTransferService.wizard.tabs.transfersDetails.price.subtitle"
              )
            }}
          </p>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BButton, BAlert } from "bootstrap-vue";
import SingleTransferForm from "@/views/foravila/services/airport-transfer/components/SingleTransferForm.vue";
import ExtrasForm from "@/views/foravila/services/airport-transfer/components/ExtrasForm.vue";
import {
  formatCurrency,
  formatDateStringToDatabaseDate
} from "@/utils/formatters";
import transferRates from "@transfer-rates";
import axios from "@/axios";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BAlert,
    SingleTransferForm,
    ExtrasForm
  },
  props: {
    booking: {
      type: [Object, null],
      default: null
    },
    validationTrigger: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      arrivalTransfer: null,
      returnTransfer: null,
      extras: null,
      airportLocation: null
    };
  },
  computed: {
    accommodation() {
      return this.booking?.accommodation || null;
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    accommodationLocation() {
      return this.booking?.accommodation?.location || null;
    },
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
    adults() {
      return this.booking?.adults || 0;
    },
    children() {
      return this.booking?.children || 0;
    },
    babies() {
      return this.booking?.babies || 0;
    },
    passengersNumber() {
      return this.adults + this.children + this.babies;
    },
    transferExtrasRates() {
      return transferRates.extras;
    },
    transferCityRates() {
      if (!this.accommodationLocation) return null;
      let rate = transferRates.cities.find(
        city => city.slug === this.accommodationLocation.citySlug
      );
      if (!rate) rate = transferRates.base;
      return rate;
    },
    transferExtrasPrice() {
      if (!transferRates?.extras) return null;
      if (!this.extras) return 0;

      let price = 0;

      if (this.extras.boosterSeats > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BOOSTER_SEAT"
        );
        price += extra ? extra.price * this.extras.boosterSeats : 0;
      }
      if (this.extras.babyTrolleys > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BABY_TROLLEY"
        );
        price += extra ? extra.price * this.extras.babyTrolleys : 0;
      }
      if (this.extras.maxiCosis > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "MAXI_COSI"
        );
        price += extra ? extra.price * this.extras.maxiCosis : 0;
      }
      if (this.extras.babySeats > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BABY_SEAT"
        );
        price += extra ? extra.price * this.extras.babySeats : 0;
      }
      if (this.extras.bicycles > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "BICYCLE"
        );
        price += extra ? extra.price * this.extras.bicycles : 0;
      }
      if (this.extras.golfSets > 0) {
        const extra = transferRates.extras.find(
          extraItem => extraItem.code === "GOLF_SET"
        );
        price += extra ? extra.price * this.extras.golfSets : 0;
      }

      return price;
    },
    totalExtrasPrice() {
      if (!this.arrivalTransfer) return 0;
      if (!this.returnTransfer) return this.transferExtrasPrice;
      return this.transferExtrasPrice * 2;
    },
    arrivalTransferPrice() {
      if (!this.transferCityRates) return null;
      if (!this.arrivalTransfer) return 0;
      return this.getPriceByPassengers(this.transferCityRates, this.arrivalTransfer.passengers)
    },
    arrivalTransferPriceWithExtras() {
      if (!this.arrivalTransfer) return 0;
      return this.arrivalTransferPrice + (this.transferExtrasPrice || 0);
    },
    returnTransferPrice() {
      if (!this.transferCityRates) return null;
      if (!this.returnTransfer) return 0;
      return this.getPriceByPassengers(this.transferCityRates, this.returnTransfer.passengers)
    },
    returnTransferPriceWithExtras() {
      if (!this.returnTransfer) return 0;
      return this.returnTransferPrice + (this.transferExtrasPrice || 0);
    },
    totalPrice() {
      if (!this.transferCityRates || !transferRates?.extras) return null;
      return (
        this.arrivalTransferPriceWithExtras + this.returnTransferPriceWithExtras
      );
    },
    totalPriceText() {
      return formatCurrency(this.totalPrice);
    },
    arrivalTransferPickupLocation() {
      return this.airportLocation;
    },
    arrivalTransferDropoffLocation() {
      return this.accommodationLocation;
    },
    returnTransferPickupLocation() {
      return this.accommodationLocation;
    },
    returnTransferDropoffLocation() {
      return this.airportLocation;
    },
    details() {
      return {
        arrivalTransfer: this.arrivalTransfer
          ? {
              ...this.arrivalTransfer,
              extras: this.extras,
              extrasPrice: this.transferExtrasPrice,
              basePrice: this.arrivalTransferPrice,
              pvpPrice: this.arrivalTransferPriceWithExtras
            }
          : null,
        returnTransfer: this.returnTransfer
          ? {
              ...this.returnTransfer,
              extras: this.extras,
              extrasPrice: this.transferExtrasPrice,
              basePrice: this.returnTransferPrice,
              pvpPrice: this.returnTransferPriceWithExtras
            }
          : null,
        extras: this.extras,
        extrasPrice: this.totalExtrasPrice,
        totalPrice: this.totalPrice
      };
    }
  },
  watch: {
    booking(booking) {
      if (!booking) return;
      this.initArrivalTransfer();
      this.initReturnTransfer();
    },
    details: {
      handler(details) {
        this.$emit("updated", details);
      },
      deep: true
    }
  },
  created() {
    this.fetchAirportLocation();
  },
  mounted() {
    this.initArrivalTransfer();
    this.initReturnTransfer();
  },
  methods: {
    initArrivalTransfer() {
      this.arrivalTransfer = {};
      this.arrivalTransfer.pickupLocation = this.arrivalTransferPickupLocation;
      this.arrivalTransfer.dropoffLocation = this.arrivalTransferDropoffLocation;
      this.arrivalTransfer.pickupDate = this.booking?.checkin
        ? formatDateStringToDatabaseDate(this.booking.checkin)
        : null;
      this.arrivalTransfer.pickupTime = null;
      this.arrivalTransfer.passengers = this.passengersNumber;
      this.arrivalTransfer.flightNumber = null;
      this.arrivalTransfer.valid = false;
    },
    initReturnTransfer() {
      this.returnTransfer = {};
      this.returnTransfer.pickupLocation = this.returnTransferPickupLocation;
      this.returnTransfer.dropoffLocation = this.returnTransferDropoffLocation;
      this.returnTransfer.pickupDate = this.booking?.checkout
        ? formatDateStringToDatabaseDate(this.booking.checkout)
        : null;
      this.returnTransfer.pickupTime = null;
      this.returnTransfer.passengers = this.passengersNumber;
      this.returnTransfer.flightNumber = null;
      this.returnTransfer.valid = false;
    },
    addReturnTrip() {
      this.initReturnTransfer();
    },
    removeReturnTrip() {
      this.returnTransfer = null;
    },
    onArrivalTransferUpdated(transfer) {
      this.arrivalTransfer = transfer;
    },
    onReturnTransferUpdated(transfer) {
      this.returnTransfer = transfer;
    },
    onExtrasUpdated(extras) {
      this.extras = extras;
    },
    fetchAirportLocation() {
      axios
        .get("/places?type=AIRPORT&location.regionSlug=mallorca")
        .then(response => {
          if (response.data["hydra:totalItems"] > 0) {
            // eslint-disable-next-line prefer-destructuring
            this.airportLocation = response.data["hydra:member"][0].location;
          }
        });
    },
    getPriceByPassengers(rates, passengers) {
      if (!rates || !passengers) return null;
      switch(true) {
        case passengers >= 1 && passengers <= 4: return rates.oneToFour;
        case passengers >= 5 && passengers <= 6: return rates.fiveToSix;
        case passengers >= 7 && passengers <= 8: return rates.sevenToEight;
        case passengers >= 9 && passengers <= 12: return rates.nineToTwelve;
        case passengers >= 13 && passengers <= 16: return rates.thirteenToSixteen;
        default: return rates.seventeenToTwentithree || null;
      }
    }
  }
};
</script>

<style lang="scss">
.transfer-form-remove-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
</style>
