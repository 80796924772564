<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        lg="4"
        class="d-flex align-items-top mb-4 mb-lg-0 pr-0 pr-lg-5 "
      >
        <div>
          <h1
            class="text-uppercase fr-font-weight-super-bold fr-title-mobile-size fr-title-desktop-size"
          >
            {{ $t("pages.airportTransferService.highlights.title") }}
          </h1>
          <h4 class="fr-subtitle-mobile-size fr-subtitle-desktop-size">
            {{ $t("pages.airportTransferService.highlights.subtitle") }}
          </h4>
        </div>
      </b-col>
      <b-col cols="12" lg="8">
        <b-row>
          <b-col cols="6">
            <div
              class="d-md-flex align-items-md-center mb-md-5 mb-4 bg-transparent p-4"
            >
              <feather-icon
                icon="CalendarIcon"
                class="text-primary  mr-0 mr-md-4"
                size="40"
              />
              <div class="mt-3 mt-md-0">
                <div class="text-dark font-weight-bold">
                  {{
                    $t(
                      "pages.airportTransferService.highlights.freeCancellation"
                    )
                  }}
                </div>
                <small>
                  {{
                    $t(
                      "pages.airportTransferService.highlights.freeCancellationDetails"
                    )
                  }}
                </small>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div
              class="d-md-flex align-items-md-center mb-md-5 mb-4 bg-transparent p-4"
            >
              <feather-icon
                size="40"
                icon="WatchIcon"
                class="text-primary mr-0 mr-md-4"
              />
              <div class="mt-3 mt-md-0">
                <div class="text-dark font-weight-bold">
                  {{ $t("pages.airportTransferService.highlights.onTime") }}
                </div>
                <small>
                  {{
                    $t("pages.airportTransferService.highlights.onTimeDetails")
                  }}
                </small>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-md-flex align-items-md-center bg-transparent p-4">
              <feather-icon
                size="40"
                icon="PhoneCallIcon"
                class="text-primary  mr-0 mr-md-4"
              />
              <div class="mt-3 mt-md-0">
                <div class="text-dark font-weight-bold">
                  {{
                    $t("pages.airportTransferService.highlights.localSupport")
                  }}
                </div>
                <small>
                  {{
                    $t(
                      "pages.airportTransferService.highlights.localSupportDetails"
                    )
                  }}
                </small>
              </div>
            </div>
          </b-col>
          <b-col cols="6">
            <div class="d-md-flex align-items-md-center bg-transparent p-4">
              <feather-icon
                size="40"
                icon="KeyIcon"
                class="text-primary  mr-0 mr-md-4"
              />
              <div class="mt-3 mt-md-0">
                <div class="text-dark font-weight-bold">
                  {{ $t("pages.airportTransferService.highlights.direct") }}
                </div>
                <small>
                  {{
                    $t("pages.airportTransferService.highlights.directDetails")
                  }}
                </small>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: { BCard, BRow, BCol }
};
</script>
