<template>
  <b-row class="mb-4">
    <b-col
      cols="12"
      md="6"
      lg="7"
    >
      <div class="d-flex bg-light p-4">
        <b-avatar
          variant="light-success"
          :size="avatarSize"
          class="mr-3"
        >
          <feather-icon
            :size="avatarIconSize"
            icon="ThumbsUpIcon"
          />
        </b-avatar>
        <div>
          <h6>
            {{ $t('pages.airportTransferService.wizard.tabs.summary.tips.1Title') }}
          </h6>
          <p class="mb-0">
            {{ $t('pages.airportTransferService.wizard.tabs.summary.tips.1Text') }}
          </p>
        </div>
      </div>

      <div class="d-flex mt-4 bg-light p-4">
        <b-avatar
          variant="light-success"
          :size="avatarSize"
          class="mr-3"
        >
          <feather-icon
            :size="avatarIconSize"
            icon="ThumbsUpIcon"
          />
        </b-avatar>
        <div>
          <h6>
            {{ $t('pages.airportTransferService.wizard.tabs.summary.tips.2Title') }}
          </h6>
          <p class="mb-0">
            {{ $t('pages.airportTransferService.wizard.tabs.summary.tips.2Text') }}
          </p>
        </div>
      </div>

      <div class="d-flex mt-4 bg-light p-4">
        <b-avatar
          variant="light-success"
          :size="avatarSize"
          class="mr-3"
        >
          <feather-icon
            :size="avatarIconSize"
            icon="ThumbsUpIcon"
          />
        </b-avatar>
        <div>
          <h6>
            {{ $t('pages.airportTransferService.wizard.tabs.summary.tips.3Title') }}
          </h6>
          <p class="mb-0">
            {{ $t('pages.airportTransferService.wizard.tabs.summary.tips.3Text') }}
          </p>
        </div>
      </div>
    </b-col>

    <b-col
      cols="12"
      md="6"
      lg="5"
      class="mt-4 mt-md-0"
    >
      <div class="bg-light p-4">
        <h5 class="mb-3">
          {{ $t('Summary') }}
        </h5>

        <summary-transfer-item
          v-if="arrivalTransfer"
          :transfer="arrivalTransfer"
          :accommodation-name="accommodationName"
          pickup-location-airport
          :hide-divider="!returnTransfer && !extras.length"
        />

        <summary-transfer-item
          v-if="returnTransfer"
          :transfer="returnTransfer"
          :accommodation-name="accommodationName"
          dropoff-location-airport
          :hide-divider="!extras.length"
        />

        <template v-if="extras.length">
          <div
            class="d-flex justify-content-between align-items-center font-weight-bold text-primary mb-3"
          >
            <div>
              {{ $t('pages.airportTransferService.wizard.tabs.summary.extraServices') }}
            </div>
            <div>
              {{ extrasPrice }}
            </div>
          </div>

          <div
            v-for="extra, index in extras"
            :key="`extra-${index}`"
            class="d-flex mt-1"
          >
            <span class="mr-2">
              <small>
                {{ extra.amount }} x {{ extra.name }}
              </small>
            </span>
          </div>
        </template>

        <div class="d-flex justify-content-between align-items-center bg-primary bg-lighten-5 px-3 py-4 mt-4">
          <div class="font-weight-bold h5 mb-0">
            {{ $t('Total') }}
          </div>
          <div class="h3 font-weight-bolder mb-0">
            {{ totalPrice }}
          </div>
        </div>
      </div>

      <!-- <validation-observer ref="summaryFormRules">
        <b-form
          id="confirm-transfer-form"
          class="mt-3"
        >
          <validation-provider
            #default="{ errors }"
            name="policy"
            rules="required|policy"
          >
            <b-form-checkbox
              v-model="policyAccepted"
              :state="errors.length > 0 ? false : null"
              class="mb-2"
            >
              <small class="font-weight-light">
                {{ $t('pages.airportTransferService.wizard.tabs.summary.acceptThe') }}
                <a href="">
                  {{ $t('pages.airportTransferService.wizard.tabs.summary.privacyPolicy') }}
                </a>
                {{ $t('pages.airportTransferService.wizard.tabs.summary.and') }}
                <a href="">
                  {{ $t('pages.airportTransferService.wizard.tabs.summary.generalTerms') }}
                </a>
              </small>
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form>
      </validation-observer> -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BAvatar,
  // BForm, BFormCheckbox,
} from 'bootstrap-vue'
import SummaryTransferItem from '@/views/foravila/services/airport-transfer/components/SummaryTransferItem.vue'
import { formatCurrency } from '@/utils/formatters'
// import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, policy } from '@foravila-validations'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    // BForm,
    // BFormCheckbox,
    SummaryTransferItem,
    // ValidationProvider,
    // ValidationObserver,
  },
  props: {
    booking: {
      type: [Object, null],
      default: null,
    },
    transfers: {
      type: [Object, null],
      default: null,
    },
    validationTrigger: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      policyAccepted: true,
      required,
      policy,
    }
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase()
    },
    isMobile() {
      return this.$store.getters['app/isMobile']
    },
    avatarSize() {
      return this.isMobile ? 'sm' : 'lg'
    },
    avatarIconSize() {
      return this.isMobile ? '12' : '18'
    },
    accommodation() {
      return this.booking?.accommodation || null
    },
    accommodationName() {
      return this.accommodation?.name || null
    },
    arrivalTransfer() {
      return this.transfers?.arrivalTransfer || null
    },
    returnTransfer() {
      return this.transfers?.returnTransfer || null
    },
    extras() {
      if (!this.transfers?.extras) return []
      const extras = []

      Object.entries(this.transfers.extras).forEach(([key, value]) => {
        if (!value) return
        let name = key
        switch (key) {
          case 'boosterSeats':
            name = this.$t('pages.airportTransferService.wizard.boosterSeatFree')
            break
          case 'babyTrolleys':
            name = this.$t('pages.airportTransferService.wizard.babyTrolleyFree')
            break
          case 'maxiCosis':
            name = this.$t('pages.airportTransferService.wizard.maxiCosiFree')
            break
          case 'babySeats':
            name = this.$t('pages.airportTransferService.wizard.babySeatFree')
            break
          case 'bicycles':
            name = this.$t('pages.airportTransferService.wizard.bicycle')
            break
          case 'golfSets':
            name = this.$t('pages.airportTransferService.wizard.golfSet')
            break
          default:
            name = key
            break
        }
        extras.push({ name, amount: value })
      })

      return extras
    },
    extrasPrice() {
      return formatCurrency(this.transfers?.extrasPrice)
    },
    totalPrice() {
      return formatCurrency(this.transfers?.totalPrice)
    },
  },
  watch: {
    // currentLanguage() {
    //   // ! We use nextTick to allow vee-validate to update the validation messages
    //   // TODO: Working but improvement needed
    //   this.$nextTick(() => {
    //     this.switchValidationLocale()
    //   })
    // },
    // policyAccepted(accepted) {
    //   this.$emit('updated', accepted)
    // },
    // validationTrigger() {
    //   this.validateFormRules()
    // },
  },
  mounted() {
    // TODO: eliminar això quan tornem a activar es checkbox
    this.$emit('updated', true)
  },
  methods: {
    // switchValidationLocale() {
    //   localize(this.currentLanguage)
    // },
    // validateFormRules() {
    //   if (!this.$refs.summaryFormRules) return
    //   this.$refs.summaryFormRules.validate()
    // },
  },
}
</script>
